<template>
  <header
    class="fixed top-0 right-0 left-0 z-12 bg-bgGreen flex justify-between items-center px-4 lg:px-20 lg:pl-32 py-4 lg:py-6 h-24"
  >
    <Logo />

    <div>
      <div v-if="$store.state.general.loggedIn" class="flex">
        <button
          @click="logout"
          class="text-ansGreen text-base py-3 px-3 lg:px-5 font-source rounded"
        >
          Log out
        </button>

        <button @click="navOpen" class="block lg:hidden">
          <img src="@/assets/images/icons/menu.svg" alt="icons" />
        </button>

        <img
          src="@/assets/images/null_female.png"
          alt=""
          class="hidden lg:block w-10 h-10 rounded-full border border-ansBlack"
        />
      </div>

      <div v-else>
        <router-link
          v-if="$route.name == 'Login' || $route.name == 'Track'"
          :to="{ name: 'Home' }"
          class="text-ansGreen text-base lg:text-xl py-3 px-3 lg:px-5 font-source rounded"
        >
          Return Home
        </router-link>

        <router-link
          v-else
          :to="{ name: 'Track' }"
          class="bg-ansGreen rounded items-center flex gap-3 text-white text-xs lg:text-xl py-3 px-3 lg:px-5 font-source font-semibold"
        >
          <span>Track your transaction</span>
          <i class="fas fa-long-arrow-alt-right"></i>
        </router-link>
      </div>
    </div>
  </header>
</template>

<script>
import Logo from "@/components/app/Logo.vue";
export default {
  name: "Header",

  components: {
    Logo,
  },

  methods: {
    navOpen() {
      this.$store.commit("general/toggleNavBar");
    },
    logout() {
      this.$store.commit("general/logout");
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style scoped>
header {
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  /* border-bottom: 2px groove rgb(185, 178, 178); */
  /* z-index: 1; */
}
</style>
